import { useState } from "react";
import CheckinList from "../CheckinList";
import Scan from "../Scan";
import Setting from "../Setting";
import './style.css';

function Nav() {

  const [page, setPage] = useState("scan");

    return (
      <div className="Nav flex flex-col h-screen justify-between">
        <div className=" h-screen pb-12">
          {page==="scan" && <Scan />}
          {page==="setting" && <Setting />}
          {page==="checkinlist" && <CheckinList />}
        </div>
        <div className="h-12 absolute bottom-0 w-full flex flex-row leading-10 text-xl font-bold bg-green-100">
          <button  
            className={`w-1/3 hover:bg-green-200 active:bg-green-400 ${page==="setting"?"bg-green-300":""}`}
            onClick={() => setPage("setting")}
          >
            ⚙️Setting
          </button>
          <button  
            className={`w-1/3 hover:bg-green-200 active:bg-green-400 ${page==="scan"?"bg-green-300":""}`}
            onClick={() => setPage("scan")}>
            🎫Scan
          </button>
          <button  
            className={`w-1/3 hover:bg-green-200 active:bg-green-400 ${page==="checkinlist"?"bg-green-300":""}`}
            onClick={() => setPage("checkinlist")}>
            📋 List
          </button>
        </div>
      </div>
    );
  }
  
  export default Nav;