import { useEffect, useState } from "react";
import { getList } from "../../lib/HTTP";

function Setting() {
  const [hidden, setHidden] = useState(true);
  const [origin, setOrigin] = useState("");
  const [hash, setHash] = useState("");
  const [counter, setCounter] = useState(0);

  const [title, setTitle] = useState("");
  const [event, setEvent] = useState("");
  const [participantCount, setParticipantCount] = useState(0);
  const [checkinsCount, setCheckinsCount] = useState(0);

  const reload = async () => {
    const reponse = await getList({}).catch(error => {
      console.log("catch error: ", error);
    });
    //console.log("reponse.data: ", reponse?.data);
    if(reponse?.data){
      setTitle(reponse?.data?.title);
      setEvent(reponse?.data?.event);
      setParticipantCount(reponse?.data.participantCount);
      setCheckinsCount(reponse?.data.checkinsCount);
    }
  }

  const handleDivClick = () => {
    if(counter>4){
      setHidden(false);
      setCounter(0);
      loadLocal()
    }else{
      setHidden(true);
      setCounter(s=>s+1);
    }    
  }

  const loadLocal = () => {
    const or = localStorage.getItem("origin");
    if(or) setOrigin(or)
    const ha = localStorage.getItem("hash");
    if(ha) setHash(ha)
  }

  useEffect(()=>{
    reload();
  }, []);

    return (
      <div className="Setting h-full flex flex-col space-y-2" onClick={handleDivClick}>
        <div className="w-full h-8 bg-green-200 ">settings </div>
          <div className=" w-full">event : {event}</div>
          <div className=" w-full">chekin list : {title}</div>
          <div className=" w-full">participant count : {participantCount}</div>  
          <div className=" w-full">checkins count : {checkinsCount}</div>  
        
        {!hidden && <div className="w-full">
          <div className=" w-full">origin : {origin}</div>
          <div className=" w-full">hash : {hash}</div>  
        </div>}
        {/* <div>counter : {counter}</div> */}
      </div>
    );
  }
  
  export default Setting;