import { useEffect, useState } from "react";
import { check, getList } from "../../lib/HTTP";
import './style.css';
import _ from "underscore";

const audioSuccess = new Audio('/sound/success.mp3');
const audioError = new Audio('/sound/error.mp3');

function CheckinList() {
  const [title, setTitle] = useState("");
  const [participantCount, setParticipantCount] = useState(0);
  const [checkinsCount, setCheckinsCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(20);
  const [start, setStart] = useState(0);

  const reload = async () => {
    setStart(0)
    const reponse = await getList({search: searchText, limit, start: 0}).catch(error => {
      console.log("catch error: ", error);
    });
    //console.log("reponse.data: ", reponse?.data);
    if(reponse?.data){
      setTitle(reponse?.data.title);
      setParticipantCount(reponse?.data.participantCount);
      setCheckinsCount(reponse?.data.checkinsCount);
      setRows(reponse?.data?.data||[]);
    }
  }

  const loadMore = async () => {
    //console.log("loadMore: ");
    //console.log("start: ", start);
    //console.log("participantCount: ", participantCount);
    const dontLoad = start > participantCount || (start==0 );
    //console.log("dontLoad: ", dontLoad);
    if(dontLoad) return;
    const reponse = await getList({search: searchText, limit, start}).catch(error => {
      console.log("catch error: ", error);
    });
    //console.log("reponse.data: ", reponse?.data);
    if(reponse?.data){
      setTitle(reponse?.data.title);
      setParticipantCount(reponse?.data.participantCount);
      setCheckinsCount(reponse?.data.checkinsCount);
      setRows(r => [...r, ...(reponse?.data?.data||[])]);
      //setRows(reponse?.data?.data||[]);
    }
  }

  const handleSubmit = (e) => {    
    reload();
    e.preventDefault();
  }

  const handleCheck = async (row) => {
    const confirmation = window.confirm(`You confirm heckin ${row?.email||row?.hash||"attendee"}`);
    if(!confirmation) return;  

    const response = await check(row?.hash).catch( (error) => {
      console.log("error catch :", error);
    });    
    checkOnTheListView(row, response?.data);
    if( response?.data?.status==="success" ){
      await audioSuccess.play();
    }else{
      await audioError.play();
    }
  }

  const checkOnTheListView = (row, data) => { //console.log("data: ", data);
    if(!data?.data) return;
    const oldRows = [...rows];
    const exist = _.findWhere(oldRows, {hash: row.hash});
    if(exist){
      exist.checkedin = !!data?.data;
      exist.checkedinDate = data?.data?.created_at;
    }
    setRows(oldRows);
  }

  const  handleScroll = (e) => {
    /* console.log("e.target.scrollHeight: ", e.target.scrollHeight);
    console.log("e.target.scrollTop: ", e.target.scrollTop);
    console.log("e.target.clientHeight: ", e.target.clientHeight); */
    const bottom = (e.target.scrollHeight - Math.ceil(e.target.scrollTop)) <= e.target.clientHeight;
    //console.log("bottom: ", bottom);
    if (bottom) { 
      setStart(s => s+20);
     }
  }

  useEffect(()=>{
    reload();
  }, []);

  useEffect(()=>{
    loadMore();
  }, [start]);

    return (
      <div className="CheckinList h-full flex flex-col " >
      <div className="w-full h-8 bg-green-200">
        <span>{title||"checkin list"} ({new Date().toLocaleString()})</span>
        <button className="text-xl right-3 absolute" onClick={reload}>🔄</button>
      </div>
      <div className="w-full h-8 bg-green-200">
        <form onSubmit={handleSubmit}>
        search : <input value={searchText} onChange={e => setSearchText(e.target.value)} /> {checkinsCount}/{participantCount}
        </form>        
      </div>
      <div className="CheckinList__table w-full mb-4 scroll-y" onScroll={handleScroll}>
        {rows.map((row, rowIndex) => 
          <div key={`attendee-row-key-${rowIndex}`} className="w-full flex flex-row pace-x-1 text-xs p-2 border-2 bg-slate-100">
            <span className="mx-0 font-bold w-8/12 overflow-x-scroll">{row?.email}</span>
            <span className="mx-1">{row?.hash} </span>
            {row?.checkedin ? <button className="ml-auto right-0 mr-4">✅</button> :
            <button className="ml-auto right-0 mr-4" onClick={()=>handleCheck(row)}>⬜</button>}
          </div>
        )}
      </div>
    </div>
    );
  }
  
  export default CheckinList;