import axios from 'axios';

const params = new URLSearchParams(window.location.search);
/* console.log("params: ", params);
console.log("hash: ", params.get("hash")); */
const hash = params.get("hash");
const origin = params.get("origin");
if(hash){
  localStorage.setItem("hash", hash);
}
if(origin){
  localStorage.setItem("origin", decodeURIComponent(origin));
}

const getOrigin = () => {
  return localStorage.getItem("origin") || process.env.DEFAULT_API_ORIGIN || "https://api.evey.live";
}
const getHash = () => {
  return localStorage.getItem("hash");
}

const savedHash = getHash();
const savedOrigin = getOrigin();
const baseURL = `${savedOrigin}/checkin-lists/${savedHash}`;

//console.log("baseURL: ", baseURL)
//console.log("savedHash: ", savedHash)
const HTTP = axios.create({ baseURL });
 

const check = (participantHash) => {
  return HTTP.post("/check", {hash: participantHash});
}

const getList = (params) => {
  return HTTP.get("/getList", {params});
}

export  {HTTP, check, getList, getHash, getOrigin};