
import './App.css';
import Nav from './component/Nav';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    /* const params = new URLSearchParams(window.location.search);
    const hash = params.get("hash");
    const origin = params.get("origin");
    if(hash){
      localStorage.setItem("hash", hash);
    }
    if(origin){
      localStorage.setItem("origin", decodeURIComponent(origin));
    } */
  }, [])
  return (
    <div className="App"><Nav /></div>
  );
}

export default App;
