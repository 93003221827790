import React, { useState } from 'react';

function SelectCam({devices, deviceId, setDeviceId, fetchDevices}) {

  const [show, setShow] = useState(false);

  const selectDevice = (id) => {
    setDeviceId(id);
    localStorage.setItem("default_deviceId", id);
    setShow(false)
  }

  const hadleToogle = ()=> {
    setShow(s => {
      fetchDevices(); 
      return !s;
    });
  }

  return (
    <>
        {show && <div className="absolute bottom-12 mb-16 right-2 rounded-lg  flex flex-col-reverse" >
          {devices?.map((device, deviceIndex) => 
             <button key={`cam-btn-select-key-${deviceIndex}`} className={`rounded-3xl text-center align-middle w-40 my-1 border-2 ${device?.deviceId===deviceId ? "bg-green-300":"bg-green-100"}`} 
             onClick={()=>{selectDevice(device?.deviceId)}}>
             {device?.label}
            </button>
          )}          
        </div>}
        <button className="absolute bottom-16 right-2  w-12 h-12 rounded-3xl text-center align-middle text-4xl " onClick={hadleToogle}>          
          {show ? 
            <p className=" text-2xl pb-2 rounded-3xl">❌</p> : 
            <p>📷 </p>
          }
        </button>
    </>
      
  );
}
  
  export default SelectCam;